




import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';

@Component({
  name: 'reports',
  components: {BaseNavigationPage}
})
export default class Reports extends mixins(Navigation) {
  public DASHBOARD: string = 'dashboard'
  public BENCHMARKS: string = 'benchmarks'

  get navItems() {
    const {DASHBOARD, BENCHMARKS} = this
    const name = this.$route.name
    return [
      {
        title: 'Overview',
        type: DASHBOARD,
        name: DASHBOARD,
        selected: name === DASHBOARD
      },
      {
        title: 'Benchmarks',
        type: BENCHMARKS,
        name: BENCHMARKS,
        selected: name === BENCHMARKS
      }
    ];
  }

  public onNavItemSelected(navItem) {
    this.$router.push({name: navItem.name, params: {}});
  }

  public created() {
    this.selectPage(5);
  }
}
